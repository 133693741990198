import React from 'react';

function Icon(props: any) {
  const { size = 16, color = '#212121', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M.75 20.243a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M2.69 2.69a1.5 1.5 0 011.06-.44h9a1.5 1.5 0 011.5 1.5v5.237a.75.75 0 01-1.5 0V3.75h-9v16.5a.75.75 0 01-1.5 0V3.75c0-.398.158-.78.44-1.06z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M12.75 9a.75.75 0 01.75-.75h6.75a1.5 1.5 0 011.5 1.5v1.112a.75.75 0 01-1.5 0V9.75H13.5a.75.75 0 01-.75-.75zM5.25 6.75A.75.75 0 016 6h3a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75zM6.75 12.75A.75.75 0 017.5 12h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zM5.25 16.5a.75.75 0 01.75-.75h3a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75zM17.654 14.612a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0v-.75a.75.75 0 01.75-.75zM17.654 19.112a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0v-.75a.75.75 0 01.75-.75z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M17.654 14.237a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm-5.25 3.75a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M17.091 16.862a.188.188 0 000 .375h1.125a1.688 1.688 0 110 3.375H16.53a.75.75 0 010-1.5h1.687a.187.187 0 100-.375h-1.125a1.688 1.688 0 010-3.375h1.688a.75.75 0 010 1.5H17.09z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
