import { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { FormContext } from '../form/context';
import Select, { OptionProps, SelectProps } from '../select';

export interface SelectFieldProps extends SelectProps {
  name: string;
  type: 'select';
  onAfterChange?: (value?: OptionProps) => void;
  noMargin?: boolean;
}

export default function SelectField(props: SelectFieldProps) {
  const { name, disabled, readOnly, onAfterChange, onChange, onBlur, ...rest } =
    props;
  const context = useContext(FormContext);
  const { control } = useFormContext<any>();
  const {
    field: { onBlur: fieldOnBlur, ...restField },
    fieldState,
  } = useController({ control, name });

  const _disabled = !context.editable || readOnly || disabled;
  const _readOnly = !context.editable || readOnly;
  const error = fieldState.error?.message;

  return (
    <Select
      {...rest}
      {...restField}
      disabled={_disabled}
      error={error}
      readOnly={_readOnly}
      onBlur={(event) => {
        onBlur?.(event);
        fieldOnBlur?.();
      }}
      onChange={(value) => {
        onChange?.(value);
        if (!onChange) {
          restField.onChange(value);
          if (onAfterChange) {
            const found = rest.data.find((curr) => {
              if (typeof curr === 'string') {
                return curr === value;
              }
              return curr.value === value;
            }) as OptionProps;
            onAfterChange(found);
          }
        }
      }}
    />
  );
}
