import produce from 'immer';
import { useContext } from 'react';
import { useController } from 'react-hook-form';

import MainFilePicker from './main-picker';
import PreviewPicker from './preview-picker';
import { fileStyles } from './styles.css';
import { BaseElementInputProps } from '../field/field-type';
import { FormContext } from '../form/context';
import FormControl from '../form-control';

export interface MultipleFilePickerProps extends BaseElementInputProps {
  type: 'files';
  label?: string;
  valueKey: string;
  previewKey: string;
  disabled?: boolean;
  noErrorText?: boolean;
  maxWidth?: string;
  resizeImage?: boolean;
  resizeWidth?: number;
  resizeHeight?: number;
  required?: boolean;
  noMargin?: boolean;
  onAfterChange?: (files: any) => void;
  isFile?: boolean;
}

export default function MultipleFilePicker(props: MultipleFilePickerProps) {
  const {
    valueKey,
    previewKey,
    resizeImage,
    resizeWidth,
    resizeHeight,
    maxWidth,
    required,
    noMargin,
    onAfterChange,
    isFile,
  } = props;
  const { field, fieldState } = useController({ name: props.name });
  const context = useContext(FormContext);
  const disabled = props.disabled || !context.editable;

  const handleDeleteFile = (index) => {
    const newFiles = produce(field.value, (draft) => {
      draft.splice(index, 1);
    });
    field.onChange(newFiles);
    onAfterChange?.(newFiles);
  };

  const handleAddOrUpdateFile = (
    files: { filename: string; url: string }[],
    index?: number,
  ) => {
    const newFiles = produce(field.value || [], (draft) => {
      files.map((item) => {
        if (index !== undefined) {
          draft[index][valueKey] = item.filename;
          draft[index][previewKey] = item.url;
        } else {
          draft.push({
            [valueKey]: item.filename,
            [previewKey]: item.url,
          });
        }
      });
    });
    field.onChange(newFiles);
    onAfterChange?.(newFiles);
  };

  const _onPicked = (
    files: { filename: string; url: string }[],
    idx: number,
  ) => {
    handleAddOrUpdateFile(files, idx);
  };

  return (
    <FormControl
      required={required}
      label={props.label}
      error={fieldState.error?.message}
      noMargin={noMargin}
    >
      <div style={{ maxWidth }}>
        {Array.isArray(field.value) && (
          <div className={fileStyles.mainPreviewContainer}>
            {field.value.map((item, idx) => {
              return (
                <>
                  <PreviewPicker
                    disabled={disabled}
                    onDelete={() => handleDeleteFile(idx)}
                    preview={item[props.previewKey]}
                    onPicked={(files) => _onPicked(files, idx)}
                    resizeHeight={resizeHeight}
                    resizeImage={resizeImage}
                    resizeWidth={resizeWidth}
                    isMultiple
                  />
                </>
              );
            })}
          </div>
        )}
        <MainFilePicker
          disabled={disabled}
          onPicked={(files) => handleAddOrUpdateFile(files)}
          resizeHeight={resizeHeight}
          resizeImage={resizeImage}
          resizeWidth={resizeWidth}
          isMultiple
          isFile={isFile}
        />
      </div>
    </FormControl>
  );
}
