import { Checkbox as RawCheckbox, CheckboxGroupProps } from '@mantine/core';
import Separator from 'components/common/separator';
import { moduleStyles } from 'modules/styles.css';
import React, { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { checkboxGroupStyles } from './styles.css';
import Checkbox from '../../checkbox';
import { FormContext } from '../../form';
import Text from '../../text';

type CheckboxData = {
  label: string;
  value: string;
};

export interface CheckboxGroupFieldProps
  extends Omit<CheckboxGroupProps, 'children'> {
  name: string;
  type: 'checkbox-group';
  onAfterChange?: (value) => void;
  disabled?: boolean;
  readOnly?: boolean;
  data: CheckboxData[];
  noMargin?: boolean;
  label?: string;
}

export default function CheckboxGroupField(props: CheckboxGroupFieldProps) {
  const {
    name,
    onAfterChange,
    disabled,
    readOnly,
    data,
    noMargin,
    label,
    ...rest
  } = props;
  const context = useContext(FormContext);
  const { control } = useFormContext<any>();
  const { field, fieldState } = useController({ control, name });

  const _disabled = !context.editable || disabled || readOnly;
  const error = fieldState.error?.message;
  return (
    <RawCheckbox.Group
      value={field.value}
      error={error}
      onChange={(value) => {
        field.onChange(value);
        onAfterChange?.(value);
      }}
      {...rest}
      style={{
        marginBottom: !noMargin ? 16 : 0,
      }}
    >
      <div className={moduleStyles.fullContainer}>
        <Text textVariant="BodyDefault">{label}</Text>
        <Separator gap={8} direction="vertical" />
        <div className={checkboxGroupStyles.fieldContainer}>
          {data.map((cbx, idx) => (
            <div key={`${cbx.label}-${idx}`}>
              <Checkbox {...cbx} disabled={_disabled} />
              <Separator gap={8} direction="vertical" />
            </div>
          ))}
        </div>
      </div>
    </RawCheckbox.Group>
  );
}
