import React from 'react';

function Icon(props) {
  const { size = 8, color = '#d44333' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 256 256"
    >
      <path fill="none" d="M0 0H256V256H0z" />
      <circle cx="128" cy="128" r="104" />
    </svg>
  );
}

export default Icon;
