import { MutateOptions } from '@tanstack/react-query';
import { ApiError } from 'api-hooks/common/model';
import { UploadFileParam, UploadInput } from 'api-hooks/upload/upload.model';
import notification from 'common/helpers/notification';
import { camelizeKeys } from 'humps';

interface Props {
  acceptedFiles: File[];
  setIsUploading?: (value: React.SetStateAction<boolean>) => void;
  uploadFileParam: (
    variables: UploadInput,
    options?:
      | MutateOptions<UploadFileParam, ApiError, UploadInput, unknown>
      | undefined,
  ) => Promise<any>;
  onPicked: (files: { filename: string; url: string }[]) => void;
  resizeImage?: boolean;
  ResizeImageFunc?: (file: any, type: any) => Promise<File>;
  isMultiple?: boolean;
  isFile?: boolean;
}

interface OnUploadProps
  extends Omit<
    Props,
    'acceptedFiles' | 'setIsUploading' | 'onPicked' | 'isMultiple'
  > {
  fileToUpload: File;
  isFile?: boolean;
}

export function getFileUploadContentType(type: string) {
  switch (type) {
    case 'image/svg+xml':
      return 'image/svg%2Bxml';
    default:
      return type;
  }
}

async function onUpload(props: OnUploadProps) {
  const {
    fileToUpload,
    uploadFileParam,
    ResizeImageFunc,
    resizeImage,
    isFile,
  } = props;
  const method = 'PUT';
  const headers = {
    'Content-Type': fileToUpload.type,
    // 'x-goog-acl': 'public-read', // exclude
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  };

  const splits = fileToUpload.name.split('.');
  const fileExtension = splits[splits?.length - 1].toLowerCase();
  const blobUrl = URL.createObjectURL(fileToUpload).concat(`.${fileExtension}`);

  const fileBlob = Object.assign(fileToUpload, {
    preview: blobUrl,
  });

  if (isFile) {
    return {
      filename: fileToUpload.name,
      url: blobUrl,
      file: fileToUpload,
    };
  }
  let result: any = await uploadFileParam({
    contentType: getFileUploadContentType(fileToUpload.type),
  });
  result = await camelizeKeys(result);
  if (!result) {
    throw new Error('Data is undefined');
  }

  if (resizeImage && ResizeImageFunc) {
    if (resizeImage && ResizeImageFunc) {
      const file = await ResizeImageFunc(fileBlob, fileToUpload.type);
      const { name: filename, url } = result;
      const uploadResponse = await fetch(url, {
        method,
        body: await file.arrayBuffer(),
        headers,
      });
      const uploadResponseBody = await uploadResponse.text();
      if (!uploadResponse.ok) {
        throw new Error(uploadResponseBody);
      }
      return {
        filename,
        url: blobUrl,
      };
    }
  }
  const { name: filename, url } = result;

  const uploadResponse = await fetch(url, {
    method,
    body: await fileToUpload.arrayBuffer(),
    headers,
  });
  const uploadResponseBody = await uploadResponse.text();
  if (!uploadResponse.ok) {
    throw new Error(uploadResponseBody);
  }

  return {
    filename,
    url: blobUrl,
  };
}

export async function onDrop(props: Props) {
  const {
    acceptedFiles,
    setIsUploading,
    uploadFileParam,
    onPicked,
    resizeImage,
    ResizeImageFunc,
    isFile,
  } = props;
  try {
    setIsUploading?.(true);

    if (props.isMultiple) {
      const fileToUpload = acceptedFiles;
      const result = await Promise.all(
        fileToUpload.map(async (file, index) => {
          return onUpload(
            await {
              fileToUpload: file,
              uploadFileParam,
              resizeImage,
              ResizeImageFunc,
              isFile,
            },
          );
        }),
      );
      onPicked(result);
    } else {
      const fileToUpload = acceptedFiles[0];
      const result = await Promise.all([
        onUpload(
          await {
            fileToUpload,
            uploadFileParam,
            resizeImage,
            ResizeImageFunc,
            isFile,
          },
        ),
      ]);
      onPicked(result);
    }
  } catch (e) {
    notification.error({ message: (e as any)?.message });
  } finally {
    setIsUploading?.(false);
  }
}
