import { createStyles } from '@mantine/core';
import {
  DateRangePicker as RawDateRangePicker,
  DateRangePickerProps as RawDateRangePickerProps,
} from '@mantine/dates';
import classNames from 'classnames';
import colors from 'common/styles/colors';
import useCombinedRefs from 'hooks/use-combined-refs';
import { forwardRef, useRef } from 'react';

import { dateRangePickerStyles } from './styles.css';

export interface DateRangePickerProps
  extends Omit<
    RawDateRangePickerProps,
    | 'inputWrapperOrder'
    | 'type'
    | 'format'
    | 'inputFormat'
    | 'labelFormat'
    | 'firstDayOfWeek'
    | 'radius'
    | 'dayClassname'
  > {
  type?: 'date-range';
  noMargin?: boolean;
}

const useStyles = createStyles((theme) => ({
  outside: {
    color: '#ced4da !important',
  },

  weekend: {
    color: `#495057 !important`,
  },

  selected: {
    color: `${theme.white} !important`,
    backgroundColor: `${colors.bgProduct} !important`,
  },
}));

const DateRangePicker = forwardRef<HTMLInputElement, DateRangePickerProps>(
  (props, ref) => {
    const { classes, cx } = useStyles();
    const innerRef = useRef<HTMLInputElement | null>(null);
    const combinedRef: any = useCombinedRefs(ref, innerRef);
    const { className, rightSection, type, noMargin, ...rest } = props;

    return (
      <RawDateRangePicker
        ref={combinedRef}
        {...rest}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        className={classNames(
          dateRangePickerStyles,
          noMargin ? '' : 'mb16',
          className,
        )}
        radius="md"
        firstDayOfWeek="sunday"
        inputFormat="DD MMM YYYY"
        labelFormat="MMM YYYY"
        dayClassName={(date, modifiers) =>
          cx({
            [classes.outside]: modifiers.outside,
            [classes.weekend]:
              modifiers.weekend && !modifiers.outside && !modifiers.selected,
            [classes.selected]: modifiers.selectedInRange || modifiers.selected,
          })
        }
      />
    );
  },
);

DateRangePicker.displayName = 'DateRangePicker';

export default DateRangePicker;
