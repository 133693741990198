/* eslint-disable @next/next/no-img-element */
import { Loader } from '@mantine/core';
import { useGetUploadFileParam } from 'api-hooks/upload/upload.mutation';
import { CancelIcon } from 'common/assets';
import { ResizeImage } from 'common/helpers/image';
import colors from 'common/styles/colors';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';

import { ACCEPTED_FILE } from './main-picker';
import { fileStyles } from './styles.css';
import { onDrop } from './utils';
import Text from '../text';

export interface PreviewOptions {
  id?: string;
  name?: string;
  disabled?: boolean;
  preview: string;
  onPicked: (files: { filename: string; url: string }[]) => void;
  onDelete?: () => void;
  accept?: string;
  isError?: boolean;
  resizeImage?: boolean;
  resizeWidth?: number;
  resizeHeight?: number;
  isMultiple?: boolean;
  ratio?: number;
  isFile?: boolean;
}

export function PreviewContent({ url, ratio }) {
  const splits = url?.split('?')[0].split('.');
  const extension = splits[splits?.length - 1]?.toLowerCase();

  const currentUrl = url.startsWith('blob:')
    ? splits.slice(0, splits.length - 1).join('.')
    : url;

  if (['jpeg', 'jpg', 'png'].includes(extension)) {
    return (
      <img
        className={fileStyles.imagePreview}
        src={currentUrl}
        alt={extension}
        {...(ratio
          ? {
              style: {
                width: '100%',
              },
            }
          : {})}
      />
    );
  }

  if (['pdf', 'xlsx', 'docx', 'doc', 'xls'].includes(extension)) {
    const Icon = (extension) => {
      switch (extension.extension) {
        case 'xls':
        case 'xlsx':
          return (
            <img
              className={fileStyles.filePreview}
              src="/assets/file-xls.svg"
              alt={extension.extension}
            />
          );
        case 'doc':
        case 'docx':
          return (
            <img
              className={fileStyles.filePreview}
              src="/assets/file-word.png"
              alt={extension.extension}
            />
          );
        case 'pdf':
          return (
            <img
              className={fileStyles.filePreview}
              src="/assets/file-pdf.svg"
              alt={extension.extension}
            />
          );
        default:
          return (
            <img
              className={fileStyles.filePreview}
              src="/assets/global-file.png"
              alt={extension.extension}
            />
          );
      }
    };
    return (
      <div className={fileStyles.previewContainer}>
        <Icon extension={extension} />
      </div>
    );
  }

  return (
    <img
      className={fileStyles.filePreview}
      src="/assets/global-file.png"
      alt={extension}
    />
  );
}

export default function PreviewPicker(props: PreviewOptions) {
  const {
    onPicked,
    onDelete,
    preview,
    disabled,
    resizeImage,
    resizeWidth,
    resizeHeight,
    isMultiple,
    ratio,
    isFile,
  } = props;
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = React.useState(false);
  const { mutateAsync: uploadFileParam } = useGetUploadFileParam();

  const ResizeImageFunc = React.useCallback(
    async (file, type) => {
      const result: File = (await ResizeImage({
        imageToResize: file,
        width: resizeWidth!,
        height: resizeHeight!,
        type,
      })) as File;
      return result;
    },
    [resizeHeight, resizeWidth],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) =>
      onDrop({
        acceptedFiles: files,
        onPicked,
        ResizeImageFunc,
        setIsUploading,
        uploadFileParam,
        resizeImage,
        isMultiple,
        isFile,
      }),
  });

  //@ts-ignore
  const { onClick: openFilePicker, ...rootProps } = getRootProps({
    refKey: 'innerRef',
  });

  const onRootClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!isUploading) {
        const splits = preview?.split('?')[0].split('.');
        const currentUrl = preview.startsWith('blob:') ? splits[0] : preview;
        window.open(currentUrl);
      }
    },
    [isUploading, preview],
  );

  return (
    <div
      className={fileStyles.previewContainer}
      onClick={onRootClick}
      {...rootProps}
      {...(ratio
        ? {
            style: {
              width: '100%',
            },
          }
        : {})}
    >
      <div
        className={fileStyles.imagePreviewContainer}
        {...(ratio
          ? {
              style: {
                paddingTop: `${100 / ratio}%`,
                width: '100%',
              },
            }
          : {})}
      >
        {isUploading ? (
          <div className={fileStyles.loadingContainer}>
            <Loader size={20} color={colors.productNormal} />
          </div>
        ) : (
          <>
            <PreviewContent url={preview} ratio={ratio} />
            {!disabled && (
              <>
                <div
                  className={fileStyles.removeImage}
                  onClick={(e) => {
                    !!onDelete && onDelete();
                    e.stopPropagation();
                  }}
                >
                  <CancelIcon />
                </div>
                <div
                  className={fileStyles.editComponent}
                  onClick={(e) => {
                    openFilePicker && openFilePicker(e);
                    e.stopPropagation();
                  }}
                >
                  <Text
                    textVariant="BodyBoldSmaller"
                    style={{ color: 'white' }}
                  >
                    {t('common:edit')}
                  </Text>
                </div>
              </>
            )}
            <input
              {...getInputProps()}
              id={props.id}
              name={props.name}
              accept={ACCEPTED_FILE}
              disabled={disabled}
            />
          </>
        )}
      </div>
    </div>
  );
}
