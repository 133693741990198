import React from 'react';

function Icon(props: any) {
  const { size = 16, color = '#212121', ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M12 10.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM8.25 13a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M12 16.75a4.874 4.874 0 00-3.9 1.95.75.75 0 11-1.2-.9 6.376 6.376 0 0110.2 0 .75.75 0 11-1.2.9 4.873 4.873 0 00-3.9-1.95z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M18.75 2.5a1.5 1.5 0 011.5 1.5v16.5a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V4a1.5 1.5 0 011.5-1.5h13.5zm0 18V4H5.25v16.5h13.5z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.25 6.25A.75.75 0 019 5.5h6A.75.75 0 0115 7H9a.75.75 0 01-.75-.75z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
