import Resizer from 'react-image-file-resizer';

interface ResizeImageProps {
  imageToResize: any;
  width: number;
  height: number;
  type?: string;
}

export async function ResizeImage(
  props: ResizeImageProps,
): Promise<string | File | Blob | ProgressEvent<FileReader>> {
  const { imageToResize, type = 'JPEG', width, height } = props;

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      imageToResize,
      width,
      height,
      type,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
    );
  });
}
