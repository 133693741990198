import { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import DatePicker, { DatePickerProps } from '../date-picker';
import { FormContext } from '../form/context';

export interface DatePickerFieldProps extends DatePickerProps {
  name: string;
  type: 'date';
}

export default function DatePickerField(props: DatePickerFieldProps) {
  const { name, disabled, readOnly, ...rest } = props;
  const context = useContext(FormContext);
  const { control } = useFormContext<any>();
  const { field, fieldState } = useController({ control, name });

  const _disabled = !context.editable || readOnly || disabled;
  const _readOnly = !context.editable || readOnly;

  const error = fieldState.error?.message;

  return (
    <DatePicker
      {...rest}
      {...field}
      disabled={_disabled}
      error={error}
      readOnly={_readOnly}
      aria-readonly={_readOnly}
    />
  );
}
