import React from 'react';

function Icon(props: any) {
  const { size = 20, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 3.125a6.875 6.875 0 100 13.75.625.625 0 110 1.25A8.125 8.125 0 1118.125 10a.625.625 0 11-1.25 0A6.875 6.875 0 0010 3.125z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 5c.345 0 .625.28.625.625v3.75h3.75a.625.625 0 110 1.25H10A.625.625 0 019.375 10V5.625c0-.345.28-.625.625-.625zM12.367 15.32c0-.346.28-.626.625-.626h5a.625.625 0 010 1.25h-5a.625.625 0 01-.625-.625z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.492 12.194c.345 0 .625.28.625.625v5a.625.625 0 11-1.25 0v-5c0-.345.28-.625.625-.625z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
