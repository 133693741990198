import Separator from 'components/common/separator';
import useCombinedRefs from 'hooks/use-combined-refs';
import { forwardRef, useRef } from 'react';

import { dateTimePickerStyles } from './styles.css';
import DatePicker from '../date-picker';
import FormControl from '../form-control';
import TimeInput from '../time-input';

export interface DateTimePickerProps {
  type?: 'date-time';
  noMargin?: boolean;
  className?: {
    datePicker?: string;
    timePicker?: string;
  };
  label?: string;
  error?: React.ReactNode | boolean;
  required?: boolean;
  disabled?: boolean;
  value?: Date | null;
  placeholder?: {
    datePicker?: string;
    timePicker?: string;
  };
  onChange?: (val: Date) => void;
  description?: React.ReactNode;
}

const DateTimePicker = forwardRef<HTMLInputElement, DateTimePickerProps>(
  (props, ref) => {
    const innerRef = useRef<HTMLInputElement | null>(null);
    const combinedRef: any = useCombinedRefs(ref, innerRef);
    const {
      className,
      type,
      noMargin,
      label,
      placeholder,
      error,
      description,
      ...rest
    } = props;

    return (
      <FormControl
        label={label}
        required={rest.required}
        error={error}
        description={description}
      >
        <div className={dateTimePickerStyles.container}>
          <div className={dateTimePickerStyles.datePickerContent}>
            <DatePicker
              ref={combinedRef}
              {...rest}
              placeholder={placeholder?.datePicker}
              error={!!error}
              noMargin
              aria-readonly
            />
          </div>
          <Separator gap={16} />
          <TimeInput
            {...rest}
            onChange={(value) => {
              const time = props.value;
              time?.setMinutes(value.getMinutes());
              time?.setHours(value.getHours());
              props.onChange?.(time!);
            }}
            placeholder={placeholder?.timePicker}
            error={!!error}
            noMargin
          />
        </div>
      </FormControl>
    );
  },
);

DateTimePicker.displayName = 'DateTimePicker';

export default DateTimePicker;
