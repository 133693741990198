import { Navigation } from 'containers/navigation';
import isEmpty from 'lodash/isEmpty';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import {
  FormProvider,
  FormProviderProps,
  SubmitHandler,
} from 'react-hook-form';

import { FormContext, FormStateProps } from './context';
import { formStyles } from './styles.css';
import { Button } from '../button';
import { Input } from '../field';

export * from './context';

enum RequestMethod {
  GET = 'GET',
  HEAD = 'HEAD',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  OPTIONS = 'OPTIONS',
}

interface FormProps {
  methods: Omit<FormProviderProps<any>, 'children'>;
  children: React.ReactNode;
  method?: RequestMethod;
  action?: string;
  style?: any;
  defaultEditable?: boolean;
  onSubmit: SubmitHandler<any>;
  navigation?: Navigation;
  setNavigations?: React.Dispatch<
    React.SetStateAction<{
      navigations: Omit<Navigation, 'isActive'>[];
      activeKey?: string | undefined;
    }>
  >;
}

interface FormFooterProps {
  showCancel?: boolean;
  showDelete?: boolean;
  showSubmit?: boolean;
  onDelete?: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
  renderRight?: React.ReactNode;
  renderBeforeSubmit?: React.ReactNode;
  renderLeft?: React.ReactNode;
  label?: string;
}

export function FormFooter(props: FormFooterProps) {
  const { t } = useTranslation();
  const {
    showCancel = false,
    showDelete = false,
    showSubmit = true,
    onDelete,
    isLoading,
    renderRight,
    renderLeft,
    renderBeforeSubmit,
    onCancel,
    label,
  } = props;
  return (
    <div className={formStyles.buttonContainer}>
      <div className={formStyles.buttonLeftContent}>
        {renderLeft}
        {showDelete && (
          <>
            <Button
              variant="secondary"
              type="button"
              onClick={onDelete}
              loading={isLoading}
              error
            >
              {t('common:delete')}
            </Button>
            <div
              style={{
                marginLeft: 24,
              }}
            />
          </>
        )}
      </div>
      <div className={formStyles.buttonRightContent}>
        {showCancel && (
          <>
            <Button variant="secondary" onClick={onCancel}>
              {t('common:cancel')}
            </Button>
            <div
              style={{
                marginLeft: 16,
              }}
            />
          </>
        )}
        {renderBeforeSubmit}
        {showSubmit && <Input type="submit" loading={isLoading} text={label} />}
        {renderRight}
      </div>
    </div>
  );
}

export default function Form(props: FormProps) {
  const [isEditable, setIsEditable] = React.useState(
    props.defaultEditable !== undefined ? props.defaultEditable : true,
  );
  const {
    methods,
    style,
    children,
    action,
    method = 'POST',
    onSubmit,
    setNavigations,
  } = props;

  const value = React.useMemo<FormStateProps>(
    () => ({
      editable: isEditable && !methods.formState.isSubmitting,
      setIsEditable,
    }),
    [isEditable, methods.formState.isSubmitting],
  );
  const isWarn =
    !isEmpty(methods.formState.dirtyFields) &&
    !methods.formState.isSubmitSuccessful;

  React.useEffect(() => {
    setNavigations &&
      setNavigations((prev) => ({
        ...prev,
        navigations: prev.navigations.map((nav) => ({
          ...nav,
          isWarn: nav.key === props.navigation?.key ? isWarn : nav.isWarn,
        })),
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWarn]);

  return (
    <FormContext.Provider value={value}>
      <FormProvider {...methods}>
        <form
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            ...style,
          }}
          method={method}
          action={action}
          onSubmit={(e) => {
            if (!action) {
              e.preventDefault();
              methods.handleSubmit(onSubmit)();
            }
          }}
        >
          {children}
        </form>
      </FormProvider>
    </FormContext.Provider>
  );
}
