import { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { FormContext } from '../form/context';
import NumberInput, { NumberInputProps } from '../number-input';

export interface NumberInputFieldProps extends Omit<NumberInputProps, 'value'> {
  name: string;
  type: 'number';
  onAfterChange?: (val?: number) => void;
}

export default function NumberInputField(props: NumberInputFieldProps) {
  const { name, disabled, readOnly, type, onAfterChange, ...rest } = props;
  const context = useContext(FormContext);
  const { control } = useFormContext<any>();
  const { field, fieldState } = useController({ control, name });

  const _disabled = !context.editable || readOnly || disabled;
  const _readOnly = !context.editable || readOnly;
  const error = fieldState.error?.message;

  return (
    <NumberInput
      {...rest}
      {...field}
      onChange={(val) => {
        field.onChange(val);
        onAfterChange?.(val);
      }}
      disabled={_disabled}
      error={error}
      readOnly={_readOnly}
    />
  );
}
