import { createStyles } from '@mantine/core';
import {
  DatePicker as RawDatePicker,
  DatePickerProps as RawDatePickerProps,
} from '@mantine/dates';
import classNames from 'classnames';
import { CalendarIcon } from 'common/assets';
import colors from 'common/styles/colors';
import { initialDate } from 'common/utils/date';
import { format } from 'date-fns';
import useCombinedRefs from 'hooks/use-combined-refs';
import { forwardRef, useRef } from 'react';

import { datePickerStyles } from './styles.css';

export interface DatePickerProps
  extends Omit<
    RawDatePickerProps,
    | 'inputWrapperOrder'
    | 'type'
    | 'format'
    | 'inputFormat'
    | 'labelFormat'
    | 'firstDayOfWeek'
    | 'radius'
    | 'dayClassname'
  > {
  type?: 'date';
  noMargin?: boolean;
}

const useStyles = createStyles((theme) => ({
  outside: {
    color: '#ced4da !important',
  },

  weekend: {
    color: `#495057 !important`,
  },

  selected: {
    color: `${theme.white} !important`,
    backgroundColor: `${colors.bgProduct} !important`,
  },
}));

const today = format(new Date(), 'dd MMM yyyy');
const todayStyle = {
  color: colors.textProduct,
  fontWeight: 500,
};

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (props, ref) => {
    const { classes, cx } = useStyles();
    const innerRef = useRef<HTMLInputElement | null>(null);
    const combinedRef: any = useCombinedRefs(ref, innerRef);
    const {
      className,
      rightSection,
      defaultValue = initialDate(),
      type,
      noMargin,
      ...rest
    } = props;

    return (
      <RawDatePicker
        ref={combinedRef}
        defaultValue={defaultValue}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        className={classNames(
          datePickerStyles,
          noMargin ? '' : 'mb16',
          className,
        )}
        radius="md"
        firstDayOfWeek="sunday"
        inputFormat="DD MMM YYYY"
        labelFormat="MMM YYYY"
        icon={<CalendarIcon size={16} />}
        dayClassName={(date, modifiers) =>
          cx({
            [classes.outside]: modifiers.outside,
            [classes.weekend]: modifiers.weekend && !modifiers.outside,
            [classes.selected]: modifiers.selected,
          })
        }
        {...rest}
        renderDay={(date) => {
          const day = date.getDate();
          const curr = format(date, 'dd MMM yyyy');

          return (
            <div
              {...(curr === today &&
              (rest.value
                ? format(rest.value as Date, 'dd MMM yyyy') !== curr
                : false)
                ? {
                    style: todayStyle,
                  }
                : {})}
            >
              {day}
            </div>
          );
        }}
      />
    );
  },
);

DatePicker.displayName = 'DatePicker';

export default DatePicker;
