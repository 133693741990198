import { sub } from 'date-fns';

export const convertDate = function (date) {
  const time = new Date(date?.getTime());
  time?.setHours(0, 0, 0, 0);
  return time?.toISOString();
};

export const initialDate = () => {
  const date = new Date();
  date.setMilliseconds(0);
  date.setSeconds(0);
  return date;
};

export const initialEndAt = () => {
  const date = new Date();
  date.setHours(23, 59, 59, 59);
  return date;
};

export const initialStartAt = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

export const convertDateEnd = (date) => {
  const _date = date;
  _date.setHours(23, 59, 59, 0);
  const temp = _date.toISOString();
  return temp.substring(0, temp.lastIndexOf('.')) + '.999999Z';
};

export const convertDateStart = (date) => {
  const _date = date;
  _date.setHours(0, 0, 0, 0);
  return _date;
};

export const aMonthBefore = () =>
  sub(convertDateStart(new Date()), { days: 30 });
