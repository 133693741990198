import {
  ActionIcon as RawActionIcon,
  ActionIconProps as RawActionIconProps,
} from '@mantine/core';
import classNames from 'classnames';
import { ButtonHTMLAttributes, forwardRef } from 'react';

import { actionButtonStyles } from './styles.css';

export interface ActionIconProps
  extends Omit<RawActionIconProps, 'children' | 'size' | 'variant' | 'radius'>,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children' | 'color'> {
  size?: 'default' | 'small';
  children: (size: number) => React.ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'transparent';
  error?: boolean;
}

const ActionIcon = forwardRef<HTMLButtonElement, ActionIconProps>(
  (props, ref) => {
    const {
      variant = 'default',
      size = 'default',
      className,
      children: _children,
      error,
      ...rest
    } = props;
    const _variant =
      variant === 'default'
        ? 'filled'
        : variant === 'secondary'
        ? 'outline'
        : variant === 'tertiary'
        ? 'light'
        : 'subtle';

    const children = _children(size === 'default' ? 20 : 16);

    return (
      <RawActionIcon
        ref={ref}
        {...rest}
        variant={_variant}
        className={classNames(
          actionButtonStyles(
            !error
              ? { variant: _variant }
              : ({
                  error:
                    variant === 'tertiary' || variant === 'transparent'
                      ? 'white'
                      : _variant,
                } as any),
          ),
          className,
        )}
        children={children}
        size={size === 'default' ? 36 : 28}
        radius="md"
      />
    );
  },
);

ActionIcon.displayName = 'ActionIcon';

export default ActionIcon;
