import ButtonField, { ButtonFieldProps } from './button';
import CheckboxField, { CheckboxFieldProps } from './checkbox';
import CheckboxGroupField, { CheckboxGroupFieldProps } from './checkbox-group';
import ColorSwatchField, { ColorSwatchFieldProps } from './color-swatch';
import DatePickerField, { DatePickerFieldProps } from './date-picker';
import DateRangePickerField, {
  DateRangePickerFieldProps,
} from './date-range-picker';
import DateTimePickerField, {
  DateTimePickerFieldProps,
} from './date-time-picker';
import MultiSelectField, { MultiSelectFieldProps } from './multi-select';
import NumberInputField, { NumberInputFieldProps } from './number-input';
import PasswordInputField, { PasswordInputFieldProps } from './password-input';
import RadioGroupField, { RadioGroupFieldProps } from './radio';
import SelectField, { SelectFieldProps } from './select';
import SwitchField, { SwitchFieldProps } from './switch';
import TextareaInputField, { TextareaInputFieldProps } from './text-area-input';
import TextInputField, { TextInputFieldProps } from './text-input';
import TimeInputField, { TimeInputFieldProps } from './time-input';
import {
  MultipleFilePicker,
  MultipleFilePickerProps,
  SingleFilePicker,
  SingleFilePickerProps,
} from '../file-picker';

export function Input(
  props:
    | ColorSwatchFieldProps
    | ButtonFieldProps
    | TextInputFieldProps
    | CheckboxFieldProps
    | DatePickerFieldProps
    | DateRangePickerFieldProps
    | MultiSelectFieldProps
    | NumberInputFieldProps
    | PasswordInputFieldProps
    | SingleFilePickerProps
    | MultipleFilePickerProps
    | RadioGroupFieldProps
    | SelectFieldProps
    | SwitchFieldProps
    | DateTimePickerFieldProps
    | TimeInputFieldProps
    | TextareaInputFieldProps
    | CheckboxGroupFieldProps,
) {
  switch (props.type) {
    case 'checkbox':
      return <CheckboxField {...props} />;
    case 'date':
      return <DatePickerField {...props} />;
    case 'date-time':
      return <DateTimePickerField {...props} />;
    case 'date-range':
      return <DateRangePickerField {...props} />;
    case 'number':
      return <NumberInputField {...props} />;
    case 'password':
      return <PasswordInputField {...props} />;
    case 'radio-group':
      return <RadioGroupField {...props} />;
    case 'select':
      return <SelectField {...props} />;
    case 'select-multi':
      return <MultiSelectField {...props} />;
    case 'switch':
      return <SwitchField {...props} />;
    case 'time':
      return <TimeInputField {...props} />;
    case 'submit':
      return <ButtonField {...props} />;
    case 'file':
      return <SingleFilePicker {...props} />;
    case 'files':
      return <MultipleFilePicker {...props} />;
    case 'color-swatch':
      return <ColorSwatchField {...props} />;
    case 'text-area':
      return <TextareaInputField {...props} />;
    case 'checkbox-group':
      return <CheckboxGroupField {...props} />;
    default:
      return <TextInputField {...props} />;
  }
}
