import { useContext } from 'react';
import { useController } from 'react-hook-form';

import MainFilePicker from './main-picker';
import PreviewPicker from './preview-picker';
import { fileStyles } from './styles.css';
import { BaseElementInputProps } from '../field/field-type';
import { FormContext } from '../form/context';
import FormControl from '../form-control';
import ReadOnlyText from '../read-only-text';

export interface SingleFilePickerProps extends BaseElementInputProps {
  type: 'file';
  label?: string;
  required?: boolean;
  previewName: string;
  disabled?: boolean;
  maxWidth?: string;
  resizeImage?: boolean;
  resizeWidth?: number;
  resizeHeight?: number;
  noMargin?: boolean;
  onAfterChange?: (file: { filename: string; url: string }) => void;
  ratio?: number;
  isFile?: boolean;
}

export default function SingleFilePicker(props: SingleFilePickerProps) {
  const {
    maxWidth,
    resizeHeight,
    resizeWidth,
    resizeImage,
    required,
    noMargin,
    onAfterChange,
    ratio,
    isFile,
  } = props;
  const { field, fieldState } = useController({ name: props.name });
  const { field: previewField } = useController({
    name: props.previewName,
  });
  const context = useContext(FormContext);
  const disabled = props.disabled || !context.editable;

  if (!context.editable && !previewField.value && !field.value) {
    return <ReadOnlyText value="-" label={props.label} />;
  }

  const _onPicked = (files: { filename: string; url: string; file: any }[]) => {
    const file = files[0];
    field.onChange(isFile ? file?.file : file.filename);
    previewField.onChange(file.url);
    onAfterChange?.(file);
  };
  const hasValue = !!field.value && !!previewField.value;

  return (
    <FormControl
      required={required}
      label={props.label}
      error={fieldState.error?.message}
      noMargin={noMargin}
    >
      <div style={{ maxWidth }}>
        {hasValue && (
          <div className={fileStyles.mainPreviewContainer}>
            <PreviewPicker
              disabled={disabled}
              onDelete={() => {
                field.onChange(undefined);
                previewField.onChange(undefined);
              }}
              preview={previewField.value}
              onPicked={_onPicked}
              resizeHeight={resizeHeight}
              resizeImage={resizeImage}
              resizeWidth={resizeWidth}
              ratio={ratio}
              isFile={isFile}
            />
          </div>
        )}
        {!hasValue && (
          <MainFilePicker
            isError={!disabled && !!fieldState.error?.message}
            disabled={disabled}
            onPicked={_onPicked}
            resizeHeight={resizeHeight}
            resizeImage={resizeImage}
            resizeWidth={resizeWidth}
            isFile={isFile}
          />
        )}
      </div>
    </FormControl>
  );
}
